import ClipLoader from "react-spinners/ClipLoader";
export const Loader = ({ loading }) => {


  const override = {
    display: "fixed",
    top: 0,
    left: 0,
    right: 0,
  };

  return (
    <div
      className={`bg-black items-center flex justify-center fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden `}
    >
      <ClipLoader
        className="bgradient"
        loading={loading}
        cssOverride={override}
        size={200}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};
