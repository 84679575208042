import Marquee from "react-fast-marquee";

export const Memes = () => {
  return (
    <div>
      {/* Marquee section */}
      <div className="w-full items-center justify-center bg-black text-white lg:text-6xl md:text-6xl py-4 overflow-y-hidden text-2xl"
      id="Memes">
      Memes
      </div>

        <Marquee
          speed={90}
          gradient={false}
          className="meme bgradient"
        >
            <div className="flex items-center px-8 py-3">
              <img
                alt="img"
                height="300"
                src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1732961191/TIC/Memes/7_bctrvs.jpg"
              />
            </div>
            <div className="flex items-center px-8 py-3">
              <img
                alt="img"
                height="100"
                src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1732961190/TIC/Memes/8_vrpkah.jpg"
              />
            </div>
            <div className="flex items-center px-8 py-3">
              <img
                alt="img"
                height="100"
                src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1732961190/TIC/Memes/9_wn9r1l.jpg"
              />
            </div>
            <div className="flex items-center px-8 py-3">
              <img
                alt="img"
                height="100"
                src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1732961190/TIC/Memes/10_nffsen.jpg"
              />
            </div>
            <div className="flex items-center px-8 py-3">
              <img
                alt="img"
                height="300"
                src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1732961191/TIC/Memes/11_lwwp8j.jpg"
              />
            </div>
            <div className="flex items-center px-8 py-3">
              <img
                alt="img"
                height="300"
                src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1732961193/TIC/Memes/Bazinga_qragkx.gif"
              />
            </div>
            <div className="flex items-center px-8 py-3">
              <img
                alt="img"
                height="300"
                src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1732961192/TIC/Memes/12.001_yfok38.png"
              />
            </div>
        </Marquee>

    </div>
  );
};
