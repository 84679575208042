import Marquee from "react-fast-marquee";
import "./Client.css";
export const Lore = () => {
  return (
    <>
      {/**/}
      <div className="w-full items-center justify-center bg-black text-white lg:text-6xl md:text-6xl py-4 overflow-y-hidden text-2xl"
      id="Lore">
      Narrative
      </div>

      <div className="bg-[rgb(226,145,27)] bgradient relative w-full  text-black flex items-center justify-center overflow-hidden px-3 py-3">
        <div className="lg:flex md:flex justify-center items-center lore">
          <div className="flex justify-between items-center w-full z-20 ">
            <div>
 {/*              <h1 className="lg:text-4xl md:text-4xl text-3xl font-extrabold mb-4  ">
                <span className="text-[rgb(250,230,1)] font-semibold">
                  Why $TICE is the narrative of Memetokens?
                </span>
              </h1>*/}
              <h1 className="lg:text-4xl md:text-4xl text-3xl font-extrabold mb-4  ">
                <span className="text-[rgb(250,230,1)] font-semibold">
                  Turkish Icecream captures the essence of crypto
                </span>
              </h1>

              <h1 className="lg:text-3xl md:text-3xl text-xl font-bold lg:my-9">
                <p className="lg:mb-5 md:mb-5 mb-2">
                  The irresistibility of memecoins<br/> The thrill and sweet promise of profits <br/>The playful chase and tease of the market <br/> & <br/>The degen spirit that brings you back for more.
                </p><br/>
                <p className="lg:mb-2 md:mb-2">
                Because the <span className="underline">pursuit is as enjoyable as the reward itself...</span>
                </p>
              </h1>

              <p className="lg:text-3xl md:text-3xl text-xl text-white py-2">
              <br/>
                <a
                  href="https://t.me/TICEonSOL"
                  target="_blank"
                  rel="noreferrer"
                  className=" font-semibold px-4 link_text"
                >
                  Telegram
                </a>
                <a
                  href="https://x.com/TICEonSOL"
                  target="_blank"
                  rel="noreferrer"
                  className=" font-semibold px-4 link_text"
                >
                  Twitter
                </a>
                <a
                  href="https://pump.fun/coin/GeECwJb9KYPCSMN1KNEgeZSDCtNnUZt2tVjJkTmTpump"
                  target="_blank"
                  rel="noreferrer"
                  className="font-semibold px-4 link_text"
                  rel="noreferrer"
                >
                  Chart
                </a>
              </p>

            </div>
          </div>
          <img
            alt="img"
            src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1732952014/TIC/TICE-2_f0ry9f.png"
            className="one lg:flex hidden"
          />
          <img
            alt="img"
            src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1732953219/TIC/TICEdoggif-Med_kydk5g.gif"
            className="two lg:flex hidden"
          />
{/*
          <img
            alt="img"
            src="https://ik.imagekit.io/hvtgbbra5/Landing_Page/Head_3.1.png?updatedAt=1679680986953"
            className="onelore lg:flex hidden"
          />
          <img
            alt="img"
            src="https://ik.imagekit.io/hvtgbbra5/Landing_Page/Head_1.2.png?updatedAt=1679681713086"
            className="twolore lg:flex hidden"
          />
         <img
            alt="img"
            src="https://ik.imagekit.io/hvtgbbra5/Landing_Page/Head_2.1.png?updatedAt=1679680987002"
            className="three lg:flex hidden"
          />
                     <img
            alt="img"
            src="https://imaginaryones.com/_nuxt/img/skeletor.9d43844.png"
            className="four lg:flex hidden "
          />*/}
        </div>
      </div>
    </>
  );
};
