import "./App.css";
import { useEffect, useState } from "react";
import { Header } from "./components/Header";
import { Roadmap2 } from "./components/RoadMap2";
import { ClientSlider } from "./components/ClientSlider";
import { Client } from "./components/Client";
import { Loader } from "./components/Loader";

import { Client3 } from "./components/Client3";
import { Lore } from "./components/Lore";
import { Memes } from "./components/Memes";
import { Command } from "./components/Command";
import { Hero2 } from "./components/Hero2";


function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <div className="App overflow-x-hidden font-comic">
      <Header />
      {loading && <Loader loading={loading} />}
      <Hero2 />
      <Lore />
      <Memes />
      {/*<Client />
      <ClientSlider />
*/}
      <Command />

      {/*      <Roadmap2 />
      <Client3 />*/}
    </div>
  );
}

export default App;
