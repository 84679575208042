import Marquee from "react-fast-marquee";
import "./Client.css";
export const Command = () => {
  return (
    <>
      {/**/}
      <div className="w-full items-center justify-center bg-black text-white lg:text-6xl md:text-6xl py-4 overflow-y-hidden text-2xl"
      id="HowTo">
      Cone-mmandments to enjoy the ride
      </div>

      <div className="bg-[rgb(226,145,27)] bgradient relative w-full  text-black flex items-center justify-center overflow-hidden px-3 py-3">
        <div className="lg:flex md:flex justify-center items-center lore">
          <div className="flex justify-between items-center w-full z-20 ">
            <div>
              <h1 className="lg:text-3xl md:text-3xl text-lg font-bold lg:my-9">
                <p className="">
                  Thou Shalt Embrace the <span className="underline">Tease</span>
                </p><br/>
                <p className="">
                  Thou Shalt Relish the Sweet <span className="underline">Profits</span>
                </p><br/>
                <p className="">
                  Thou Shalt Nurture the <span className="underline">Degen</span> Spirit
                </p><br/>
                <p className="">
                  Thou Shalt Participate in the <span className="underline">Playful</span> Ecosystem
                </p><br/>
                <p className="">
                  Thou Shalt <span className="underline">Share</span> the Experience
                </p><br/>
                <p className="">
                  Thou Shalt <span className="underline">Enjoy</span> the Cultural Fusion
                </p><br/>
                <p className="">
                  Thou Shalt Stay <span className="underline">Hopeful</span> and <span className="underline">Persistent</span>
                </p><br/>
                <p className="">
                  Thou Shalt <span className="underline">Engage</span> with the <span className="underline">Community</span>
                </p><br/>
                <p className="">
                  Thou Shalt Remember the <span className="underline">Fun</span>
                </p><br/>
                <p className="">
                  Thou Shalt Understand <span className="underline">the Near Yet Far</span> Dynamic
                </p><br/>
              </h1>
{/*
              <p className="lg:text-3xl md:text-3xl text-xl text-white py-2">
              <br/>
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className=" font-semibold px-4 link_text"
                >
                  Telegram
                </a>
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className=" font-semibold px-4 link_text"
                >
                  Twitter
                </a>
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="font-semibold px-4 link_text"
                  rel="noreferrer"
                >
                  Chart
                </a>
              </p> */}

            </div>
          </div>
          {/*
          <img
            alt="img"
            src="https://ik.imagekit.io/hvtgbbra5/Landing_Page/Head_3.1.png?updatedAt=1679680986953"
            className="onelore lg:flex hidden"
          />
          <img
            alt="img"
            src="https://ik.imagekit.io/hvtgbbra5/Landing_Page/Head_1.2.png?updatedAt=1679681713086"
            className="twolore lg:flex hidden"
          />
         <img
            alt="img"
            src="https://ik.imagekit.io/hvtgbbra5/Landing_Page/Head_2.1.png?updatedAt=1679680987002"
            className="three lg:flex hidden"
          />
           <img
            alt="img"
            src="https://imaginaryones.com/_nuxt/img/skeletor.9d43844.png"
            className="four lg:flex hidden "
          />*/}
        </div>
      </div>

      <div className="w-full items-center justify-center bg-black text-white lg:text-6xl md:text-6xl py-4 overflow-y-hidden text-2xl"
      id="">
        <p className="lg:text-3xl md:text-3xl text-xl text-white py-2">
        <br/>
          <a
            href="https://t.me/TICEonSOL"
            target="_blank"
            rel="noreferrer"
            className=" font-semibold px-4 link_text"
          >
            Telegram
          </a>
          <a
            href="https://x.com/TICEonSOL"
            target="_blank"
            rel="noreferrer"
            className=" font-semibold px-4 link_text"
          >
            Twitter
          </a>
          <a
            href="https://pump.fun/coin/GeECwJb9KYPCSMN1KNEgeZSDCtNnUZt2tVjJkTmTpump"
            target="_blank"
            rel="noreferrer"
            className="font-semibold px-4 link_text"
            rel="noreferrer"
          >
            Chart
          </a>
        </p>
      </div>
    </>
  );
};
