import { FaCreativeCommonsNd } from "react-icons/fa";
import { useState } from "react";
import Fade from "react-reveal/Fade";
import { RxCross2 } from "react-icons/rx";
export const Header = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <>
      <div className="flex justify-between mt-0 py-2 px-6 bg-black">
        <div>
          <img
            decoding="async"
            height="10"
            src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1723193449/TIC/TICE-New_dbqscl.png"
            className="logo lazyloaded"
            alt="logo"
          />
        </div>

        <div className="py-2">
          <div className=" flex items-center justify-center  ">
            <div className=" lg:flex hidden  space-x-6 text-gray-700">

              <a
                href="#Lore"
                rel="noreferrer"
              >

                <p className="text-white text-2xl flex font-semibold items-center justify-center text-[rgb(254,221,3)] rounded-full p-2">
                Narrative
                </p>
              </a>

              <a
                href="#Memes"
                rel="noreferrer"
              >

                <p className="text-white text-2xl flex font-semibold items-center justify-center text-[rgb(254,221,3)] rounded-full p-2">
                Memes
                </p>
              </a>

              <a
                href="#HowTo"
                rel="noreferrer"
              >

                <p className="text-white text-2xl flex font-semibold items-center justify-center text-[rgb(254,221,3)] rounded-full p-2">
                How To Enjoy
                </p>
              </a>

              <a
                href="https://t.me/TICEonSOL"
                target="_blank"
                rel="noreferrer"
              >
                <div className="border-2 border-black flex items-center justify-center  rounded-full w-12 h-12 p-2">
                  <img
                    alt="img"
                    src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1706733920/Pope/telegram-white_mbdh6r.png"
                  />
                </div>
              </a>
              {/* Twitter */}
              <a
                href="https://x.com/TICEonSOL"
                target="_blank"
                rel="noreferrer"
              >
                <div className="border-2 border-black flex items-center justify-center  rounded-full w-12 h-12 p-2">
                  <img
                    alt="img"
                    src="https://res.cloudinary.com/dtl8sgdwe/image/upload/v1706733920/Pope/twitter-white_cp5mb1.png"
                  />
                </div>
              </a>

              {/* </div> */}
            </div>
          </div>
        </div>

        <div
          className="text-6xl lg:hidden text-white cursor-pointer"
          onClick={() => setShowSideBar(true)}
        >
          {!showSideBar && <FaCreativeCommonsNd />}
        </div>
        {showSideBar && (
          <Fade right>
            <div className="w-3/4 bg-[rgba(0,0,0,0.9)] fixed top-0 right-0 h-screen z-50 ">
              <div className=" w-full text-xl font-extrabold p-4 text-left  from-neutral-200">
                <div onClick={() => setShowSideBar(false)} className="p-2">
                  <RxCross2 className="text-white cursor-pointer" />
                </div>

                <div className="items-center justify-center text-center">
                <a target="" href="#Lore" rel="noreferrer">
                  <button className="bg-[#FAE232] mt-4 border-none py-2 px-6 mb-3 w-60 text-black rounded-xl text-2xl">
                    Narrative
                  </button>
                </a>
                <a target="" href="#Memes" rel="noreferrer">
                  <button className="bg-[#FAE232] mt-4 border-none py-2 px-6 mb-3 w-60 text-black rounded-xl text-2xl">
                    Memes
                  </button>
                </a>
                <a target="" href="#HowTo" rel="noreferrer">
                  <button className="bg-[#FAE232] mt-4 border-none py-2 px-6 mb-3 w-60 text-black rounded-xl text-2xl">
                    How To Enjoy
                  </button>
                </a>

                <a target="_blank" href="https://t.me/TICEonSOL" rel="noreferrer">
                  <button className="bg-[#FAE232] mt-4 border-none py-2 px-6 mb-3 w-60 text-black rounded-xl text-2xl">
                    Telegram
                  </button>
                </a>

                <a target="_blank" href="https://x.com/TICEonSOL" rel="noreferrer">
                  <button className="bg-[#FAE232] mt-4 border-none py-2 px-6 mb-3 w-60 text-black rounded-xl text-2xl">
                    X
                  </button>
                </a>

                </div>
              </div>
            </div>
          </Fade>
        )}
      </div>
    </>
  );
};
